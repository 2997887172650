@tailwind base;
@tailwind components;
@tailwind utilities;

:focus {
  outline: 0;
}

table td,
table th {
  @apply p-2 border;
}

.required-label::after {
  content: '*';
  color: red;
  margin-left: 5px;
}

body {
  background: #fafafa;
}
